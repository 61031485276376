<template>
    <!-- 客户价格批量导入 -->
    <div id="app_user_price_import">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
            </div>

            <div class="head_top_title">客户价格批量导入</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">


            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                    <el-form-item label="导入文件">
                        <!-- show-file-list:是否显示已上传文件列表  默认值 true
                        multiple:是否支持多选文件 
                        data:上传时附带的额外参数
                        auto-upload	是否在选取文件后立即进行上传
                        with-credentials 是否支持发送 cookie 凭证信息  true:支持
                        -->
                        <el-upload  ref="uploadFile"  style="display: inline-block;"
                                :action="upload_url" :multiple="false" :limit="1" :auto-upload="false"
                                :with-credentials="true"

                                :on-success="uploadResponseResult"
                                :on-error="uploadResponseResultFail"

                                :file-list="fileList"
                                
                                :on-change="uploadChange"
                                :on-remove="uploadChange"
                                
                                >
                            <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                        
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="文件格式">
                        <el-image style="margin-right:10px" fit="fill" :src="require('@/assets/img/template/user_price_import_template.png')"
                            :preview-src-list = "previewSrcList"
                         ></el-image>  
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="dataSubmit()" size="mini">提交</el-button>
                    </el-form-item>
                </el-form>
                
            
            </el-row>

            
            

        </el-row>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api.js';
import {
    htmlDecode,
    getItem
} from '../../api/tool.js';

//import axios from "axios";
export default {

    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{},

            upload_url:"",//上传url
            fileList:[],//文件列表

            previewSrcList:[require('@/assets/img/template/user_price_import_template.png')],


            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        this.form_data = this.$options.data().form_data;//单个恢复初始化
        this.upload_url = this.$options.data().upload_url;//单个恢复初始化
        this.fileList = this.$options.data().fileList;//单个恢复初始化
        
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 130);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },

        //数据提交
        dataSubmit(){
            console.log("数据提交 file_num:"+this.fileList.length);

            if(this.fileList.length == 0){
                this.$message.error("请先选择文件");
                return ;
            }
            this.$refs.uploadFile.submit();
        },
        //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            //上传url
            this.upload_url = API.getInterfaceUrl()+"/UserPriceServlet?param=userPriceBatch&session="+getItem("qxt_session")+"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
            console.log(this.upload_url);
            
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            console.log("上传响应结果res");
            console.log(res);
            this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            if(res){
                // res = JSON.parse(res);

                if(res.recode == 0){
                    var duration = 2000;
                    if(API.isEmtry(res.text)){
                        res.text = "恭喜您，联系人导入成功";
                    }else{
                        duration = 10000;
                    }
                    // this.$message.error(res.text,{duration:duration});
                    this.$message({
                        showClose: true,
                        message: res.text,
                        duration:duration,
                        type: 'warning'
                    });
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }

            
        },
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },

        

        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>
/* 卡片 */
#app_user_company_list>>>.el-card__header{
    padding: 8px 20px;
}

</style>